import axios from 'axios'
import { useAuth } from '../context/Context'
import { SERVER_URL } from '../../Config'
import { useEffect, useState } from 'react'
import { IUserQuery, UserQueryStatuses } from '../types/Types'
import './Support.css'

const Support = (): JSX.Element => {
  const { adminData } = useAuth()
  const [supports, setSupports] = useState<IUserQuery[]>([])
  const [currentStatus, setCurrentStatus] = useState('new')
  const [selectedSupports, setSelectedSupports] = useState<string[]>([])
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<Record<string, any>>()

  const fetchSupports = async (): Promise<void> => {
    try {
      const response = await axios.get<{ Items: IUserQuery[], LastEvaluatedKey?: Record<string, any> }>(
        SERVER_URL + '/admin/support',
        {
          headers: {
            eradmjwt: adminData?.token,
            status: currentStatus,
            startkey: lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey): undefined
          }
        }
      )
      setSupports(response.data?.Items)
      setLastEvaluatedKey(response.data?.LastEvaluatedKey)
    } catch (err) {
      console.error('Error occurred while fetching supports', err)
      setSupports([])
    }
  }

  const update = async (ids: string[]): Promise<void> => {
    const payload = supports
      .map((fb) => {
        if (ids.includes(fb.id) && fb.newStatus && fb.newStatus !== fb.status) {
          return {
            id: fb.id,
            newStatus: fb.newStatus
          }
        } else return null
      })
      .filter((fb) => fb)

    try {
      await axios.put(SERVER_URL + '/admin/support', payload, {
        headers: {
          eradmjwt: adminData?.token
        }
      })
      fetchSupports()
    } catch (err) {
      console.error('Something went wrong while updating support', err)
    }
  }

  useEffect(() => {
    fetchSupports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData, currentStatus])

  return (
    <div className='supportContainer'>
      <h4>Support review</h4>
      <select value={currentStatus} onChange={(e) => setCurrentStatus(e.target.value)}>
        <option value={'new'}>New</option>
        <option value={'seen'}>Seen</option>
      </select>
      <br />
      <table className="supportTable">
        <thead>
          <tr>
            <th>Id</th>
            <th>F Name</th>
            <th>L Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Select</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {supports?.length > 0 ? (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedSupports.length === supports.length}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setSelectedSupports([])
                    } else {
                      setSelectedSupports(supports.map((fb) => fb.id))
                    }
                  }}
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    update(selectedSupports)
                  }}
                >
                  Update selected
                </button>
              </td>
            </tr>
          ) : null}
          {supports?.map((fb) => {
            return (
              <tr key={fb.id}>
                <td>{fb.id}</td>
                <td>{fb.fName}</td>
                <td>{fb.lName}</td>
                <td>{fb.email}</td>
                <td>{fb.message}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedSupports.includes(fb.id)}
                    onChange={(e) => {
                      if (
                        e.target?.checked &&
                        !selectedSupports.includes(fb.id)
                      ) {
                        setSelectedSupports([...selectedSupports, fb.id])
                      } else {
                        setSelectedSupports(
                          selectedSupports.filter((sfb) => sfb !== fb.id)
                        )
                      }
                    }}
                  />
                </td>
                <td>
                  <select
                    onChange={(e) => {
                      setSupports(
                        supports.map((tfb) => {
                          if (tfb.id === fb.id) {
                            return {
                              ...fb,
                              newStatus: e.target.value as UserQueryStatuses
                            }
                          }
                          return tfb
                        })
                      )
                    }}
                    defaultValue={'new'}
                    value={fb.newStatus}
                  >
                    <option value={'new'}>New</option>
                    <option value={'seen'}>Seen</option>
                  </select>
                  <button
                    onClick={() => {
                      update([fb.id])
                    }}
                  >
                    Update
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <button onClick={() => fetchSupports()}>Load next user queries</button>
    </div>
  )
}

export default Support
