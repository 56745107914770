import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../context/Context'

const PrivateRoute = ({
  children,
  path
}: {
  children: JSX.Element
  path?: string
}): JSX.Element => {
  const { isLoggedIn } = useAuth()
  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ from: path }} />
  )
}

export default PrivateRoute
