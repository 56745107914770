import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/Context'
import Homepage from '../homepage/Homepage'
import './Header.css'

const Header = (): JSX.Element => {
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate()
  return (
    <div className="header">
      <h3>ExaRoutes admin</h3>
      <br />
      <span>
        Unauthorized access to this site is strictly prohibited. Violators will
        be subject to legal action and potential financial penalties.
      </span>
      {isLoggedIn ? (
        <>
          <br />
          <br />
          <button onClick={() => navigate('/')}>Home</button>
          <br />
          <br />
          <Homepage />
        </>
      ) : null}
    </div>
  )
}

export default Header
