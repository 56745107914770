import { useNavigate } from 'react-router-dom'

const Homepage = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div>
      <button onClick={() => navigate('/feedback')}>Feedback review</button>
      <br />
      <br />
      <button onClick={() => navigate('/support')}>
        Support queries review
      </button>
    </div>
  )
}

export default Homepage
