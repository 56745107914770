const CLIENT_SITE_NAME = 'ExaRoutes'

const environment = process.env.REACT_APP_ENV

let clientSiteURL
let serverURL

if (environment === 'production') {
    // Add production variables
} else if (environment === 'staging') {
    // Add staging variables
  clientSiteURL = 'https://staging.exaroutes.com'
  serverURL = 'https://api.staging.exaroutes.com'
} else {
  clientSiteURL = 'http://localhost:3000'
  serverURL = 'http://localhost:4000'
}

const CLIENT_SITE_URL = clientSiteURL as string
const SERVER_URL = serverURL as string

export {
  CLIENT_SITE_NAME,
  CLIENT_SITE_URL,
  SERVER_URL
}
