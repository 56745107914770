import axios from 'axios'
import { useAuth } from '../context/Context'
import { SERVER_URL } from '../../Config'
import { useEffect, useState } from 'react'
import { FeedbackStatuses, type IFeedback } from '../types/Types'
import './Feedback.css'

const Feedback = (): JSX.Element => {
  const { adminData } = useAuth()
  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([])
  const [currentStatus, setCurrentStatus] = useState('new')
  const [selectedFeedbacks, setSelectedFeedbacks] = useState<string[]>([])
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<Record<string, any>>()

  const fetchFeedbacks = async (): Promise<void> => {
    try {
      const response = await axios.get<{ Items: IFeedback[], LastEvaluatedKey?: Record<string, any> }>(
        SERVER_URL + '/admin/feedback',
        {
          headers: {
            eradmjwt: adminData?.token,
            status: currentStatus,
            startkey: lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey): undefined
          }
        }
      )
      setFeedbacks(response.data?.Items)
      setLastEvaluatedKey(response.data?.LastEvaluatedKey)
    } catch (err) {
      console.error('Error occurred while fetching feedbacks', err)
      setFeedbacks([])
    }
  }

  const update = async (ids: string[]): Promise<void> => {
    const payload = feedbacks
      .map((fb) => {
        if (ids.includes(fb.id) && fb.newStatus && fb.newStatus !== fb.status) {
          return {
            id: fb.id,
            newStatus: fb.newStatus
          }
        } else return null
      })
      .filter((fb) => fb)

    try {
      await axios.put(
        SERVER_URL + '/admin/feedback',
        payload,
        {
          headers: {
            eradmjwt: adminData?.token
          }
        }
      )
      fetchFeedbacks()
    } catch (err) {
      console.error('Something went wrong while updating feedback', err)
    }
  }

  useEffect(() => {
    fetchFeedbacks()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData, currentStatus])

  return (
    <div className='feedbackContainer'>
      <h4>Feedback review</h4>
      <select value={currentStatus} onChange={(e) => setCurrentStatus(e.target.value)}>
        <option value={'new'}>New</option>
        <option value={'seen'}>Seen</option>
      </select>
      <br/>
      <table className="feedbackTable">
        <thead>
          <tr>
            <th>Id</th>
            <th>Area</th>
            <th>Message</th>
            <th>Select</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {feedbacks?.length > 0 ? (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <input type="checkbox" checked={selectedFeedbacks.length === feedbacks.length} onChange={(e) => {
                  if (!e.target.checked){
                    setSelectedFeedbacks([])
                  } else {
                    setSelectedFeedbacks(feedbacks.map(fb => fb.id))
                  }
                }}/>
              </td>
              <td>
                <button
                  onClick={() => {
                    update(selectedFeedbacks)
                  }}
                >
                  Update selected
                </button>
              </td>
            </tr>
          ) : null}
          {feedbacks?.map((fb) => {
            return (
              <tr key={fb.id}>
                <td>{fb.id}</td>
                <td>{fb.area}</td>
                <td>{fb.message}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedFeedbacks.includes(fb.id)}
                    onChange={(e) => {
                      if (e.target?.checked && !selectedFeedbacks.includes(fb.id)) {
                        setSelectedFeedbacks([...selectedFeedbacks, fb.id])
                      } else {
                        setSelectedFeedbacks(
                          selectedFeedbacks.filter((sfb) => sfb !== fb.id)
                        )
                      }
                    }}
                  />
                </td>
                <td>
                  <select
                    onChange={(e) => {
                      setFeedbacks(
                        feedbacks.map((tfb) => {
                          if (tfb.id === fb.id) {
                            return {
                              ...fb,
                              newStatus: e.target.value as FeedbackStatuses
                            }
                          }
                          return tfb
                        })
                      )
                    }}
                    defaultValue={'new'}
                    value={fb.newStatus}
                  >
                    <option value={'new'}>New</option>
                    <option value={'seen'}>Seen</option>
                  </select>
                  <button
                    onClick={() => {
                      update([fb.id])
                    }}
                  >
                    Update
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <button onClick={() => fetchFeedbacks()}>Load next feedbacks</button>
    </div>
  )
}

export default Feedback
