import React, { createContext, useState, useContext, ReactNode } from 'react'

// Define the shape of your context data
interface AuthContextData {
  isLoggedIn: boolean
  adminData: any
  login: (data: any) => void
  logout: () => void
}

// Create the context with initial values
const AuthContext = createContext<AuthContextData>({
  isLoggedIn: false,
  adminData: undefined,
  login: (data: any) => {},
  logout: () => {}
})

// Create a provider component to wrap your app with
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [adminData, setAdminData] = useState()

  const login = (data: any) => {
    setIsLoggedIn(true)
    setAdminData(data)
    // Add your login logic here, like setting tokens or user data in local storage
  }

  const logout = () => {
    setIsLoggedIn(false)
    // Add your logout logic here, like clearing tokens or user data from local storage
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, adminData, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to consume the AuthContext
export const useAuth = () => {
  return useContext(AuthContext)
}
