import axios from 'axios'
import { useEffect, useState } from 'react'
import './Login.css'
import { useAuth } from '../context/Context'
import { SERVER_URL } from '../../Config'
import { useNavigate } from 'react-router-dom'

const Login = (): JSX.Element => {
  const { login } = useAuth()
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    id: '',
    password: '',
    mfaToken: ''
  })

  const loginAdmin = async () => {
    try {
      const response = await axios.post(
        SERVER_URL + '/admin/login',
        userData
      )
      login(response.data)
      navigate('/')
    } catch (err) {
      console.log('Error occurred while attempting to login', err)
    }
  }

  useEffect(() => {
    if (userData.id && userData.password && userData.mfaToken?.length === 6) loginAdmin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return (
    <div className="loginContainer">
      <input
        className="loginInput"
        placeholder="Id"
        onChange={(e) => setUserData({ ...userData, id: e.target.value })}
      />
      <input
        className="loginInput"
        placeholder="Password"
        type="password"
        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
      />
      <input
        className="loginInput"
        placeholder="2FA Token"
        onChange={(e) => setUserData({ ...userData, mfaToken: e.target.value })}
      />
      <button className="loginButton" onClick={loginAdmin}>
        Login
      </button>
    </div>
  )
}

export default Login
