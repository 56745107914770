import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/login/Login'
import Header from './components/header/Header'
import PrivateRoute from './components/layout/PrivateRoute'
import Feedback from './components/feedback/Feedback'
import Homepage from './components/homepage/Homepage'
import Support from './components/support/Support'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute path="/">
                <Homepage />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <PrivateRoute path="/feedback">
                <Feedback />
              </PrivateRoute>
            }
          />
          <Route
            path="/support"
            element={
              <PrivateRoute path="/support">
                <Support />
              </PrivateRoute>
            }
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
